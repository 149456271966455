
export default {
	name: "assess",
	data() {
		return {
			token: "",
			userId: "",
			searchName: '',//搜索姓名
			optionClass: 'comprehensive',//选择分类
			minCount: '0',//最小值\
			maxCount: '100',//最大值
			optionList: [{ name: '职业素养', msg: 'ggligt' },
			{ name: '沟通与协调', msg: ';yygl' },
			{ name: '学习与接受', msg: 'yjzxt' },
			{ name: '整理与完善', msg: 'sqfmyd' },
			{ name: '执行与完成', msg: 'xmzgpf' }],
			list: '',
			current: 1,
			total: 0,
			size: 0,
		};
	},

	created() {
		var _this = this;
		this.token = this.getCookie("token");
		this.userId = this.getCookie("userId");
		this.getList(this.searchName, this.optionClass, this.minCount, this.maxCount)
	},
	methods: {
		getList(searchName, optionClass, minCount, maxCount) {
			var _this = this;
			_this.$http.post('/API/PRO/selectScoreDetailList', {
				nameSMO: searchName,
				classification: optionClass,
				sta: minCount,
				end: maxCount,
				page: _this.current
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.size = response.data.data.size;
					_this.total = response.data.data.total;
				}
			})
		},
		//搜索按钮
		searchBtn() {
			var _this = this;
			if (_this.minCount || _this.maxCount) {
				this.getList(this.searchName, this.optionClass, this.minCount, this.maxCount)
			} else {
				_this.defeated("请填写分数区间")
			}

		},
		//导出
		export2Excel() {
			var _this = this;
			if (this.list !== '') {
				_this.$http.post('/API/PRO/exportExcel', {
					nameSMO: _this.searchName,
					classification: _this.optionClass,
					sta: _this.minCount,
					end: _this.maxCount,
				}, {
					headers: {
						"token": _this.token
					}
				}).then(function (response) {
					require.ensure([], () => {
						const {
							export_json_to_excel
						} = require('../../vendor/Export2Excel');
						const tHeader = ['姓名', 'SMO', '职业素养', '沟通与协调', '学习与接受', '整理与完善', '执行与完成', '综合评分'];
						const filterVal = ['crcName', 'enterpriseName', 'avgZzsy', 'avgGtyxt', 'avgXxyjs', 'avgZlyws', 'avgZxywc', 'compositeScore'];
						const data = _this.formatJson(filterVal, response.data.data.list);
						export_json_to_excel(tHeader, data, '人员评估列表');
					})
				});
			} else {
				this.$message.error('操作异常');
			}
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		},
		currentPageChanged(current) {
			this.current = current;
			this.getList(this.searchName, this.optionClass, this.minCount, this.maxCount)
		},
		toDetails(id) {
			console.log(id)
			this.$router.push({
				name: 'detail',
				params: {
					id: id
				}
			});
			this.$store.commit('getId', id)
		},
	}
}
